"use client";

import { useState } from "react";
import Button from "../Button";
import { signOut } from "@/actions/auth";
import { useRouter } from "next/navigation";

interface Props {
  className?: string
}

export default function LogoutButton(props: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    await signOut();
  }

  return <Button
    color="gray"
    {...props}
    onClick={handleClick}
    isLoading={isLoading}
  >
    Log Out
  </Button>
}