import { ComponentPropsWithoutRef } from "react";
import LoadingIcon from "./SVG/LoadingIcon";
import Link from "next/link";
import { DM_Sans } from "next/font/google";

const dmSans = DM_Sans({
  subsets: ["latin"],
  weight: ["600"],
});

type ButtonSize = "small" | "medium" | "large" | "x-large";
type ButtonColor = "primary" | "secondary" | "white" | "gray" | "black";
type Variant = "contained" | "outlined";

interface Props extends ComponentPropsWithoutRef<"button"> {
  isLoading?: boolean,
  color?: ButtonColor,
  size?: ButtonSize,
  variant?: Variant,
  href?: string,
}

export default function Button({
  type = 'button',
  color = 'primary',
  size = 'x-large',
  variant = 'contained',
  href,
  className = '',
  isLoading,
  children,
  value,
  ...props
}: Props) {

  const buttonColorClasses = variant === 'outlined' ? {
    'primary': 'shadow-[inset_0_0_0_2px_#5fd6c1] text-[#5fd6c1] hover:text-white hover:shadow-none hover:bg-[#2fb59c] focus:ring-teal-300',
    'black': 'shadow-[inset_0_0_0_2px_black] text-black hover:text-white hover:shadow-none hover:bg-gray-800',
  }: {
    'primary': 'bg-[#5fd6c1] text-white hover:bg-[#2fb59c] focus:ring-teal-300',
    'secondary': '',
    'gray': 'bg-[#F8F9F9] hover:bg-gray-200 text-black',
    'white': 'bg-[#FFFFFF] disabled:bg-[#FFFFFF] hover:bg-gray-200 text-black disabled:text-[#999999]',
    'black': 'bg-black text-white hover:bg-gray-800',
  }

  const butonSizeClasses = {
    'small': 'rounded-[3px] text-[12px] sm:text-[14px] px-3 py-[6px] sm:py-[8px]',
    'medium': 'rounded-md text-[14px] sm:text-[16px] px-4 py-[10px] sm:py-[12px]',
    'large': 'rounded-lg text-[16px] sm:text-[18px] px-5 py-[12px] sm:py-[15px]',
    'x-large': 'rounded-[5px] text-[18px] sm:text-[20px] py-[12px] sm:pt-[15px] sm:pb-[16px] px-[15px]'
  }

  const classes = buttonColorClasses[color] + " " +
    butonSizeClasses[size] + " " +
    "focus:ring-4 focus:outline-none font-semibold text-center leading-none " +
    dmSans.className + " " +
    (/(^w-|\sw-)/.test(className || '') ? '' : 'w-full ') +
    className;

  if (href) {
    return (
      <Link href={href} className={classes}>
        {isLoading && (
          <LoadingIcon className="animate-spin inline mr-2 relative -top-[2px]" />
        )}{" "}
        {children || value}
      </Link>
    )
  }
  return (
    <button
      type={type}
      {...props}
      disabled={isLoading || props.disabled}
      className={classes}>
      {isLoading && (
        <LoadingIcon className="animate-spin inline mr-2 relative -top-[2px]" />
      )}{" "}
      {children || value}
    </button>
  )
}