"use client";

import Button from "../Button";
import LogoutButton from "./LogoutButton";
import { useAuth } from "@/hooks/useAuth";

export default function AuthButton() {

  const {user, isLoading} = useAuth();

  const btnClasses = ' md:w-[230px] whitespace-nowrap ';

  if (isLoading) {
    return <></>
  }

  return (
    <div className="flex items-center gap-4 -mt-4">
      {user ? (
        <>
          <Button href="/account" className={btnClasses}>
            My Account
          </Button>
          <LogoutButton className={btnClasses}></LogoutButton>
        </>
      ) : (
        <>
          <Button href="/login" color="gray" className={btnClasses}>
            Login
          </Button>
          <Button href="/signup" className={btnClasses}>
            Sign Up
          </Button>
        </>
      )}
    </div>
  );
}
